import type { Pinia, Store } from 'pinia';
import { defineStore, getActivePinia } from 'pinia';
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { isAuthenticated as isAuthenticatedApi } from "@/api/auth/authApi";
import { call } from "@/api/lib/integration";
import { getSecret, removeSecret, SecretNames } from "@/application/plugins/secretStore";
import { resetErrors } from "@/checkup/state";
import { isNativePlatform, isProductionEnv } from '@/application/utils/envInfo';
import { ref } from "vue";
import * as Sentry from "@sentry/browser";
import { registerPushNotifications, unregisterPushNotifications } from "@/application/plugins/pushNotification";
import * as authApi from "@/api/auth/authApi";
import type { TwoFactorDevice } from "@/api/auth/types";
import type { CheckBiometryResult } from "@/application/plugins/biometricAuth";
import { biometryAuth } from "@/application/plugins/biometricAuth";
import { checkBiometry } from "@/application/plugins/biometricAuth";
import { getPreference, setPreference } from "@/application/plugins/preferences";
import { throttle } from "lodash-es";
import { routeToLogout } from "@/api/lib/routerRef";
import { useEventBus } from '@vueuse/core'
import { usePolling } from "@/application/composables/usePolling.ts";
import { useHandlungStore } from "@/actions/store/actionStore.ts";
import { useTagManager } from '@demv_systems/feu-tag-manager';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export const useUserStore = defineStore('userStore', () => {
  const isAuthenticated = ref<null|boolean>(null);
  const twoFactorDevices = ref<null|TwoFactorDevice[]>(null);
  const biometry = ref<CheckBiometryResult|null>(null);
  const isAppLocked = ref(false);
  const shouldLockApp = ref(false);
  const isUpdateingLockSetting = ref(false);

  async function fetchTwoFactorDevices(): Promise<void> {
    await call(
      authApi.getTwoFactorDevices(),
      ({ data }) => { twoFactorDevices.value = data; },
      );
  }

  async function deleteTwoFactorDevices(id: number): Promise<void> {
    await call(
      authApi.deleteTwoFactorDevice({ id }),
      () => {
        twoFactorDevices.value = twoFactorDevices.value?.filter((device) => device.id !== id) ?? [];
      },
    );
  }

  // checks backend login state and aligns client state accordingly
  // e.g. if logged in backend-wise, set logged in state in frontend
  // if logged out backend-wite, set logged out state in frontend
  async function syncLoginState() {
    if (isNativePlatform) {
      const [ac, cs] = await Promise.all([
        getSecret(SecretNames.AccessToken),
        getSecret(SecretNames.ClientSecret)
      ]);

      if (ac === null || cs === null) {
        await onLoggedOut();
        return isAuthenticated.value;
      }
    }

    await call(
      isAuthenticatedApi(),
      (value) => { isAuthenticated.value = value },
      () => { isAuthenticated.value = false },
    );

    if (isAuthenticated.value) {
      await onLoggedIn();
    } else {
      await onLoggedOut();
    }

    return isAuthenticated.value;
  }

  function $reset() {
    // isAuthenticated.value = null;
  }

  async function onLoggedOut() {
    resetErrors();

    await Promise.all([
      unregisterPushNotifications(),
      removeSecret(SecretNames.AccessToken),
      removeSecret(SecretNames.ClientSecret),
      setPreference('IsAppLocked', false),
    ]);

    if (isProductionEnv) {
      window._paq.push(['resetUserId']);
    }

    Sentry.setUser(null);

    const activePinia = getActivePinia() as ExtendedPinia;
    activePinia?._s.forEach(store => store?.$reset());

    isAppLocked.value = false;
    isAuthenticated.value = false;
  }

  async function updateBiometry(): Promise<void> {
    biometry.value = await checkBiometry();
  }

  async function onLoggedIn () {
    const activePinia = getActivePinia() as ExtendedPinia;
    activePinia?._s.forEach(store => store?.$reset());

    isAuthenticated.value = true
    const promise = useBaseDataStore().fetchBaseData();
    useUnreadHintStore().fetchUnreadHints().then();
    registerPushNotifications().then();
    useHandlungStore().fetchActions().then();
    usePolling().poll().then();
    await promise;

    Sentry.setUser({ id: useBaseDataStore().user?.id });

    if (import.meta.env.VITE_APP_GTM_ID) {
      useTagManager().init(
        useBaseDataStore().hashedUserId,
        import.meta.env.VITE_APP_GTM_ID,
      )
    }

    return;
  }

  async function checkLockscreenSettings() {
    ([isAppLocked.value, shouldLockApp.value] = await Promise.all([
      getPreference('IsAppLocked', false),
      getPreference('ShouldLockApp', false)
    ]));
  }

  async function lockApp(): Promise<void> {
    if (!isNativePlatform) return;
    if (!shouldLockApp.value) return;
    if (isUpdateingLockSetting.value) return;
    if (isAuthenticated.value !== true) return;

    isAppLocked.value = true;
    await setPreference('IsAppLocked', true);
  }

  async function unlockApp(): Promise<void> {
    if (!isAppLocked.value) return;

    try {
      isUpdateingLockSetting.value = true;
      await biometryAuth();
      isAppLocked.value = false;
      await setPreference('IsAppLocked', false);
    } catch {
    } finally {
      isUpdateingLockSetting.value = false;
    }
  }

  async function setShouldLockApp(value: boolean) {
    if (isUpdateingLockSetting.value) return;

    try {
      isUpdateingLockSetting.value = true
      await biometryAuth();
      await setPreference('ShouldLockApp', value);
      shouldLockApp.value = value;

      if (!value) {
        await setPreference('IsAppLocked', false);
        isAppLocked.value = false;
      }
    } catch {
    } finally {
      isUpdateingLockSetting.value = false;
    }
  }

  const logUserOutByApi = throttle(async () => {
    await onLoggedOut();
    await routeToLogout('Ihre Sitzung ist abgelaufen.');
  }, 1500, { leading: true, trailing: false });

  const logUserOutByUser = async () => {
    await authApi.logout();
    await onLoggedOut();
    await routeToLogout('Sie wurden erfolgreich abgemeldet.');
  };

  const bus = useEventBus<string>('apiLogout');
  bus.on(() => { logUserOutByApi().then(); });

  return {
    logUserOutByUser,

    onLoggedIn,
    onLoggedOut,
    syncLoginState,
    isAuthenticated,
    $reset,
    twoFactorDevices,
    deleteTwoFactorDevices,
    fetchTwoFactorDevices,
    biometry,
    updateBiometry,

    isAppLocked,
    shouldLockApp,
    checkLockscreenSettings,
    lockApp,
    unlockApp,
    setShouldLockApp,
  };
});
